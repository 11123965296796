<template>
	<!-- 采集器 -->
	<div class="collector-box">
		<div class="table-box">
			<el-table :data="tableData" style="width: 100%" height="100%" @row-click="rowClick" :row-class-name="setRowClass">
				<el-table-column type="expand">
					<template slot-scope="props">
						<div class="descriptions-box">
							<el-descriptions title="" :column="4" v-for="(i, index) in props.row.sdata" :key="index">
								<el-descriptions-item label="传感器编号">{{ i.sno }}</el-descriptions-item>
								<el-descriptions-item label="传感器类型">
									<el-tag size="small">{{ typeFormatter(i.type, sensorTypeOption) }}</el-tag>
								</el-descriptions-item>
							</el-descriptions>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="序号" type="index" width="60"></el-table-column>
				<el-table-column label="仪器编号" prop="cno"></el-table-column>
				<el-table-column label="状态" prop="online">
					<template slot-scope="scope">
						<span :class="scope.row.online == 1 ? 'green' : 'grey'">{{ scope.row.online == 1 ? '在线' : '离线' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="测点线" prop="plname"></el-table-column>
				<el-table-column label="采集时间间隔（s）" prop="intime"></el-table-column>
				<el-table-column label="启停" align="center">
					<template slot-scope="scope">
						<div class="table-txt-but">
							<el-button v-show="scope.row.plname == ''" type="warning" size="mini" @click.stop="open('enable', true, scope.row)">已停用</el-button>
							<el-button v-show="scope.row.plname != ''" type="primary" size="mini" @click.stop="disableCollector(scope.row)">已启用</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-but">
							<el-button type="primary" plain size="mini" icon="el-icon-edit" @click.stop="open('add-collector-equipment', true, scope.row)"></el-button>
							<el-button type="danger" plain size="mini" icon="el-icon-delete" @click.stop="deleteCollector(scope.row)"></el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 采集器添加/编辑设备 -->
		<div class="add-collector-equipment equipment-style Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editCollectorEquipment ? '编辑采集器设备' : '添加采集器设备' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-collector-equipment')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							仪器编号
						</span>
						<el-input v-show="!editCollectorEquipment" v-model="addEquipment.cno" placeholder="请输入内容"></el-input>
						<p class="cell-content" v-show="editCollectorEquipment">{{ addEquipment.cno }}</p>
					</div>
					<!--  -->
					<div class="cell" v-show="judgment('authorityList', 290, this)">
						<span class="title">所属企业</span>
						<el-select v-model="addEquipment.companyId" filterable placeholder="请选择企业">
							<el-option v-for="item in allEnterprise" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell" v-show="!editCollectorEquipment">
						<span class="title">
							<i>*</i>
							型号
						</span>
						<el-select v-model="addEquipment.type" filterable placeholder="请选择型号">
							<el-option v-for="item in typeOption" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<span class="title">采集时间间隔(s)</span>
						<el-input v-model="addEquipment.intime" placeholder="请输入内容"></el-input>
					</div>
					<div class="button-box">
						<el-button type="primary" v-show="!editCollectorEquipment" @click="addEquipments">添加</el-button>
						<el-button type="primary" v-show="editCollectorEquipment" @click="equipmentEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>

		<el-dialog :title="'采集器：' + collectorName + ' 与对应测点线'" :visible.sync="dialogCollectorProject" custom-class="collectorProject" @close="close('enable')">
			<div class="mian-box">
				<div class="header-box">
					<el-select v-model="project" placeholder="请选择选择项目" class="margin-r" @change="projectChange">
						<el-option v-for="item in projectOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<el-select v-model="pointLine" placeholder="请选择测点线" @change="pointLineChange">
						<el-option label="新增测点线" value="add"></el-option>
						<el-option v-for="item in pointLineOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="movement-box">
					<data-movement
						ref="dataMovement"
						leftTitle="传感器"
						rightTitle="测点"
						:listData="moveData"
						:leftKey="{ id: 'id', name: 'sno' }"
						:rightKey="{ id: 'id', name: 'name' }"
					></data-movement>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogCollectorProject = false">取 消</el-button>
				<el-button type="primary" @click="enableCollector">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import dataMovement from '../../../../components/dataMovement.vue';
export default {
	name: '',
	props: {
		// 权限列表
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		//选择的企业列表
		allEnterprise: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			tableData: [],
			editCollectorEquipment: false, //是否编辑采集器设备
			typeOption: [
				{
					name: 'A',
					id: 1
				}
			], //新增项目选择的型号列表
			sensorTypeOption: [
				{
					name: '静力水准仪',
					id: 1
				},
				{
					name: '倾角计',
					id: 2
				},
				{
					name: '裂缝计',
					id: 3
				},
				{
					name: '激光测距仪',
					id: 4
				}
			], //传感器类型列表
			addEquipment: {
				cno: '', // 编号
				type: '', // 设备型号
				id: '',
				companyId: '', //企业id
				intime: '' //采集时间间隔
			}, // 添加设备/设备编辑
			dialogCollectorProject: false, //采集器与对应测点线
			project: '', // 选择的项目
			projectOptions: '', // 项目选择数据
			pointLine: '', // 选择的测点线
			pointLineOptions: [], // 测点线选择数据
			moveData: {
				left: [],
				right: []
			}, //启用采集器时采集器与对应测点线关系弹窗数据
			collectorId: '', // 启用时使用的采集器id
			collectorName: '', // 启用时使用的采集器name
			requestInfo: {
				page: 1,
				size: 30,
				name: ''
			},
			refreshTotal: false, //是否刷新父组件的分页总数
			total: 0 //数据总数
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 获取列表数据
		getListData(value = {}, total = false) {
			for (let k in this.requestInfo) {
				if (value.hasOwnProperty(k)) {
					this.requestInfo[k] = value[k];
				}
			}
			this.refreshTotal = total;
			var data = {
				name: this.requestInfo.name,
				page: this.requestInfo.page,
				size: this.requestInfo.size,
				projectId: this.$parent.project_info.id,
				companyId: this.$parent.enterprise_info.id
			};
			this.axios.post('web2/dfmt/mand/odnr/gclist', data).then(res => {
				this.tableData = [];
				if (res.status) {
					this.tableData = res.data.array;
					this.total = res.data.total;
					this.$parent.collectorTotal = this.total;
					if (this.refreshTotal) {
						this.$parent.total = this.total;
					}
					this.$nextTick(function() {
						$(() => {
							let dom = $('.collector-box .el-table__row');
							$.each(dom, function(index, item) {
								if (index % 2 == 0) {
									$(this).css({
										backgroundColor: '#f2fafa'
									});
								} else {
									$(this).css({
										backgroundColor: '#f0f2fc'
									});
								}
							});
						});
					});
				}
			});
		},
		// 添加采集器设备
		addEquipments() {
			var data = {
				cno: this.addEquipment.cno,
				type: this.addEquipment.type,
				intime: this.addEquipment.intime,
				projectId: this.$parent.project_info.id
			};
			if (data.cno.toString() == '' || data.type.toString() == '') {
				this.$message({
					showClose: true,
					message: '请填写必填项',
					type: 'warning'
				});
				return;
			}
			if (this.addEquipment.companyId == '' || this.addEquipment.companyId == null) {
				data.companyId = this.$parent.enterprise_info.id;
			} else {
				data.companyId = this.addEquipment.companyId;
			}
			this.axios.post('web2/dfmt/mand/dmj/adc', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备新增成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('add-collector-equipment');
				}
			});
		},
		// 编辑采集器设备
		equipmentEdit() {
			var data = {
				id: this.addEquipment.id,
				// type: this.addEquipment.type,
				intime: this.addEquipment.intime
			};
			if (this.addEquipment.companyId != '' && this.addEquipment.companyId != null) {
				data.companyId = this.addEquipment.companyId;
			}
			this.axios.post('web2/dfmt/mand/dmm/mdc', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备信息修改成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('add-collector-equipment');
				}
			});
		},
		// 删除采集器
		deleteCollector(row) {
			this.$confirm('此操作将删除采集器：<strong>' + row.cno + '</strong> ，是否继续！', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/dfmt/mand/dmj/dlc', { id: row.id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: `采集器：${row.cno} 删除成功！`,
								type: 'success'
							});
							this.$parent.getAllList();
						}
					});
				})
				.catch(() => {});
		},
		// 获取启用采集器弹窗数据
		getEnableData() {
			let data = {
				projectId: this.$parent.project_info.id,
				companyId: this.$parent.enterprise_info.id
			};
			this.project = '';
			this.projectOptions = [];
			this.pointLine = '';
			this.pointLineOptions = [];
			this.axios.post('web2/dfmt/mand/dmm/gupl', data).then(res => {
				this.projectOptions = [];
				if (res.status) {
					this.projectOptions = res.data;
					if (res.data.length == 1) {
						this.project = res.data[0].id;
						this.pointLineOptions = res.data[0].childs;
					}
				}
			});
		},
		// 采集器与对应测点线项目选择
		projectChange(val) {
			this.pointLine = 'add';
			this.pointLineOptions = [];
			let len = this.projectOptions.length;
			for (let i = 0; i < len; i++) {
				let item = this.projectOptions[i];
				if (this.project == item.id) {
					this.pointLineOptions = item.childs;
					break;
				}
			}
		},
		// 采集器与对应测点线测点线选择
		pointLineChange(val) {
			this.$set(this.moveData, 'right', []);
			let len = this.pointLineOptions.length;
			for (let i = 0; i < len; i++) {
				let item = this.pointLineOptions[i];
				if (this.pointLine == item.id) {
					this.$set(this.moveData, 'right', this.deepCopy(item.points));
					break;
				}
			}
		},
		// 启用采集器
		enableCollector() {
			let afterMoving = this.$refs.dataMovement.getAfterMoving(); //移动后数据
			let sarray = [];
			let parray = [];
			afterMoving.left.forEach(i => {
				sarray.push(i.id);
			});
			afterMoving.right.forEach(i => {
				parray.push(i.id);
			});
			var data = {
				id: this.collectorId, //采集器id
				projectId: this.project,
				plid: this.pointLine == 'add' ? '' : this.pointLine, //测点线id
				sarray: sarray.toString(),
				parray: parray.toString()
			};
			if (data.projectId === '') {
				this.$message({
					showClose: true,
					message: '请选择项目！',
					type: 'warning'
				});
				return;
			}
			this.axios.post('web2/dfmt/mand/dmm/bcpl', data).then(res => {
				if (res.status) {
					this.$parent.getAllList();
					this.dialogCollectorProject = false;
					this.$message({
						showClose: true,
						message: this.collectorName + '启用成功！',
						type: 'success'
					});
				}
			});
		},
		// 停用采集器
		disableCollector(row) {
			this.$confirm('确定停用采集器：<strong>' + row.cno + '</strong>', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					var data = {
						id: row.id
					};
					this.axios.post('web2/dfmt/mand/dmm/rlcp', data).then(res => {
						if (res.status) {
							this.$parent.getAllList();
							this.$message({
								showClose: true,
								message: row.cno + '停用成功！',
								type: 'success'
							});
						}
					});
				})
				.catch(() => {});
		},
		// 表格行点击
		rowClick(row, column, event) {
			event.stopPropagation();
			event.preventDefault();
			if (row.hasOwnProperty('sdata')) {
				if (row.sdata.length != 0) {
					$(event.currentTarget)
						.find('.el-icon-arrow-right')
						.click();
				}
			}
		},
		// 设置表格行样式，隐藏下拉
		setRowClass({ row, rowIndex }) {
			// // console.log(row);
			// // console.log(rowIndex);
			let className = 'pointer';
			if (row.hasOwnProperty('sdata')) {
				if (row.sdata.length == 0) {
					className = 'hide-expand-icon';
				}
			} else {
				className = 'hide-expand-icon';
			}
			return className;
		},
		// 类型数据回显格式化
		typeFormatter(val, data) {
			let txt = '';
			try {
				data.forEach(item => {
					if (item.id == val) {
						txt = item.name;
						throw Error();
					}
				});
			} catch (e) {
				//TODO handle the exception
				return txt;
			}
			return txt;
		},
		// 打开弹窗
		open(val, status, data = {}) {
			if (val == 'add-collector-equipment') {
				// 打开采集器新增设备弹窗
				this.editCollectorEquipment = status;
				if (status) {
					for (let key in this.addEquipment) {
						if (data.hasOwnProperty(key)) {
							this.addEquipment[key] = data[key];
						}
					}
				}
			} else if (val == 'enable') {
				this.collectorId = data.id;
				this.collectorName = data.cno;
				this.$set(this.moveData, 'right', []);
				this.$set(this.moveData, 'left', this.deepCopy(data.sdata));
				this.getEnableData();
				this.dialogCollectorProject = true;
			}
			if (val != 'enable') {
				$('.' + val).show();
			}
		},
		// 关闭弹窗
		close(val) {
			if (val != 'enable') {
				$('.' + val).hide();
			}
			if (val == 'add-collector-equipment') {
				//恢复新增采集器设备数据
				for (let key in this.addEquipment) {
					this.addEquipment[key] = '';
				}
			} else if (val == 'enable') {
				this.$refs.dataMovement.clearData();
			}
		},
		// 清空数据赋值信息
		clearData(data) {
			// // console.log(data)
			for (let key in data) {
				if (key != 'id') {
					data[key] = '';
				} else {
					data[key] = null;
				}
			}
		}
	},
	components: {
		dataMovement
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.collector-box {
	width: 100%;
	height: 100%;

	.green {
		color: #00ff7f;
	}
	.grey {
		color: #c0c4cc;
	}

	.table-box {
		width: 100%;
		height: 100%;

		::v-deep {
			.el-table__header-wrapper {
				tr {
					background-color: #ebeef5;
				}

				.el-table__cell {
					background: none;
					font-size: 15px;
					font-weight: 600;
					color: #4c4e51;
					letter-spacing: 1px;
				}
			}

			.el-table__body-wrapper {
				.el-table__row {
					// cursor: pointer;

					.el-table__cell {
						font-size: 14px;
						color: #606266;
						padding: 10px 0;
						border: none;
					}
				}

				.hide-expand-icon {
					.el-table__expand-icon {
						display: none;
					}
				}

				.table-txt-but {
					.el-button {
						padding: 6px 6px;
					}

					.el-button + .el-button {
						margin-left: 0;
					}
				}

				.table-but {
					.el-button {
						padding: 4px 5px;
						font-size: 16px;
					}
				}

				.descriptions-box {
					padding: 0 20px 0 58px;
				}
			}
		}
	}

	// 新增/编辑弹窗输入框样式
	.Mask-box {
		position: fixed;

		.main-box {
			.pop-ups-content {
				padding: 10px;
				background-color: #ffffff;

				.cell {
					width: 100%;
					display: flex;
					// flex-direction: column;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 12px;

					::v-deep {
						.el-input__inner {
							color: #000000;
							font-size: 16px;
						}
					}

					.title {
						font-size: 15px;
						color: #606266;
						// margin-bottom: 5px;

						i {
							color: #f20000;
							font-size: 12px;
						}
					}

					.cell-content {
						width: calc(100% - 120px);
						font-size: 16px;
					}
				}

				.tips {
					word-break: break-all;
					font-size: 12px;
					margin-bottom: 12px;

					i {
						color: #f20000;
					}
				}

				.button-box {
					text-align: center;
					padding: 12px 12px 0 12px;
				}
			}
		}
	}

	// 设备添加、编辑弹窗样式
	.equipment-style {
		z-index: 1000;
		display: none;

		.main-box {
			width: 380px;
			height: 311px;

			.pop-ups-content {
				.cell {
					::v-deep {
						.el-input {
							width: calc(100% - 120px);
						}

						.el-select {
							width: calc(100% - 120px);

							.el-input {
								width: 100%;
							}
						}

						.el-switch__label * {
							font-size: 13px;
							color: #dcdfe6;
						}

						.is-active span {
							color: #409eff !important;
						}
					}

					.title {
						width: 110px;
						display: inline-block;
					}
				}
			}
		}
	}

	// 采集器与对应测点线
	::v-deep .collectorProject {
		.el-dialog__body {
			padding: 10px 20px 20px 20px;
		}

		.mian-box {
			width: 100%;
			height: 100%;

			.header-box {
				margin: 5px 0 15px 0;

				.margin-r {
					margin-right: 20px;
				}

				.el-input__icon {
					line-height: 35px;
				}
			}

			.movement-box {
				width: 100%;
				height: 400px;
			}
		}
	}
}
</style>

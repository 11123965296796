<template>
	<!-- 测点线 -->
	<div class="pointLine-box">
		<div class="table-box">
			<el-table :data="tableData" style="width: 100%" height="100%" @row-click="rowClick" :row-class-name="setRowClass">
				<el-table-column type="expand">
					<template slot-scope="props">
						<div class="descriptions-box">
							<el-descriptions title="" :column="5" v-for="(i, index) in props.row.pdata" :key="index">
								<el-descriptions-item>
									<template slot="label">
										<div class="icon-name">
											<i :class="props.row.datum == i.id ? 'iconfont icon-shoucang' : 'iconfont icon-shoucang opacity'"></i>
											<span class="name">测点名称</span>
										</div>
									</template>
									{{ i.name }}
								</el-descriptions-item>
								<el-descriptions-item label="地址" content-class-name="text-overflow">{{ i.address }}</el-descriptions-item>
								<el-descriptions-item label="传感器编号">{{ i.sno }}</el-descriptions-item>
								<el-descriptions-item label="现场图">
									<i v-show="i.pn1 != ''" class="iconfont pointer icon-tupian imgIcon" @click.stop="previewImage($event, i.pn1)"></i>
								</el-descriptions-item>
								<el-descriptions-item label="" label-style="display: none;">
									<div class="table-but">
										<el-button type="primary" plain size="mini" icon="el-icon-edit" @click.stop="open('edit-point', true, i)"></el-button>
										<el-button type="danger" plain size="mini" icon="el-icon-delete" @click.stop="deletePoint(i)"></el-button>
									</div>
								</el-descriptions-item>
							</el-descriptions>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
				<el-table-column label="测点线名称" prop="name"></el-table-column>
				<el-table-column label="地址" prop="address"></el-table-column>
				<el-table-column label="采集器编号" prop="cno"></el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-but">
							<el-button type="primary" plain size="mini" icon="el-icon-edit" @click.stop="open('edit-point-line', true, scope.row)"></el-button>
							<el-button type="danger" plain size="mini" icon="el-icon-delete" @click.stop="deletePointLine(scope.row)"></el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 添加/编辑测点线 -->
		<div class="edit-point-line Mask-box">
			<div class="edit-box main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editPointLine ? '编辑测点线' : '添加测点线' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('edit-point-line')"></span>
				</p>
				<div class="pop-ups-content">
					<p class="tips">
						<i>注意：</i>
						{{ editPointLine ? '测点线名称输入最多6个字符，且只能有字母，数字，‘+’或‘-’' : '测点线名称输入最多6个字符，且只能有字母，数字，‘+’或‘-’' }}
					</p>
					<div class="cell">
						<span class="title">
							<i>*</i>
							测点线名称
						</span>
						<div class="pointInput">
							<span class="tip-txt">BXS-</span>
							<el-input v-model="addPointLine.name" :maxlength="editPointLine ? '6' : '6'" show-word-limit placeholder="请输入测点线名称"></el-input>
						</div>
					</div>
					<div class="cell">
						<span class="title">基准点选择</span>
						<el-select v-model="addPointLine.datum" clearable placeholder="请选择">
							<el-option v-for="item in benchmarkOptions" :key="item.value" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<span class="title">位置</span>
						<el-input type="textarea" :rows="4" resize="none" v-model="addPointLine.position" placeholder="请输入内容"></el-input>
					</div>
					<div class="button-box">
						<el-button v-show="!editPointLine" :disabled="addPointLine.name == ''" type="primary" size="mini" @click="addPointLines">添加</el-button>
						<el-button v-show="editPointLine" :disabled="addPointLine.name == ''" type="primary" size="mini" @click="pointLineEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加/编辑测点 -->
		<div class="edit-point Mask-box">
			<div class="edit-box main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editPoint ? '编辑' : '添加测点' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('edit-point')"></span>
				</p>
				<div class="pop-ups-content">
					<p class="tips">
						<i>注意：</i>
						{{ editPoint ? '测点名称输入最多6个字符，且只能有字母，数字，‘+’或‘-’' : '测点名称输入最多6个字符，且只能有字母，数字，‘+’或‘-’' }}
					</p>
					<div class="input-box">
						<vue-scroll>
							<div class="cell">
								<span class="title">
									<i>*</i>
									测点名称
								</span>
								<div class="pointInput">
									<span class="tip-txt">BX-</span>
									<el-input v-model="addPoint.name" :maxlength="editPoint ? '6' : '6'" show-word-limit placeholder="请输入测点名称"></el-input>
								</div>
							</div>
							<div class="cell">
								<span class="title">位置</span>
								<el-input type="textarea" :rows="4" resize="none" v-model="addPoint.position" placeholder="请输入内容"></el-input>
							</div>
							<div class="cell but-input-cell" v-for="(i, index) in addPoint.wvs" :key="index">
								<span class="title">{{ i.name + (index + 1) }}</span>
								<el-input v-model="i.value" placeholder="请输入报警值"></el-input>
								<el-button v-if="index == 0" type="primary" plain icon="el-icon-plus" size="mini" @click.stop="addWvsInput"></el-button>
							</div>
							<div class="cell img">
								<span class="title">现场图片</span>
								<uploadImg ref="addPointImg" :configuration="addPointParameter" />
							</div>
						</vue-scroll>
					</div>

					<div class="button-box">
						<el-button v-show="!editPoint" :disabled="addPoint.name == ''" type="primary" size="mini" @click="addPoints">添加</el-button>
						<el-button v-show="editPoint" :disabled="addPoint.name == ''" type="primary" size="mini" @click="pointEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 现场图片预览 -->
		<el-image-viewer v-if="dialogVisible" :on-close="handleClose" :url-list="[sceneImgList]" />
		<!-- <el-dialog title="现场图片" :visible.sync="dialogVisible" width="70%" custom-class="scenePictures" :before-close="handleClose">
			<div class="img-box">
				<el-image fit="scale-down" id="sceneImg" style="width: 100%;height: 100%;" :src="sceneImgList">
					<div slot="error" class="image-slot">
						<i class="el-icon-picture-outline"></i>
						<span>暂无图片</span>
					</div>
				</el-image>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
import uploadImg from '../../../../components/uploadImg.vue';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
	name: '',
	props: {
		// 权限列表
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		//选择的企业列表
		allEnterprise: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			tableData: [],
			editPointLine: false, //是否编辑测点线
			editPoint: false, //是否编辑测点
			addPoint: {
				name: '', //测点名称
				position: '', // 名称/位置
				id: null,
				pn1: '',
				wvs: [
					{
						name: '报警值',
						value: ''
					}
				] // 报警值数组
			}, // 添加测点/测点编辑
			addPointLine: {
				name: '', //测点名称
				position: '', // 名称/位置
				datum: '', //基准点
				id: null
			}, // 添加测点线/测点线编辑
			benchmarkOptions: [], //基准点选择列表
			addPointParameter: {
				limit: 1, //最大文件个数
				name: 'component-addPoint' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //测点布设图图片上传
			dialogVisible: false, //现场图片弹窗是否显示
			sceneImgList: '', //现场图片地址
			requestInfo: {
				page: 1,
				size: 30,
				name: ''
			},
			refreshTotal: false, //是否刷新父组件的分页总数
			total: 0 //数据总数
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 获取数据
		getListData(value = {}, total = false) {
			for (let k in this.requestInfo) {
				if (value.hasOwnProperty(k)) {
					this.requestInfo[k] = value[k];
				}
			}
			this.refreshTotal = total;
			var data = {
				name: this.requestInfo.name,
				page: this.requestInfo.page,
				size: this.requestInfo.size,
				projectId: this.$parent.project_info.id,
				companyId: this.$parent.enterprise_info.id
			};
			this.axios.post('web2/dfmt/mand/odnr/gplist', data).then(res => {
				this.tableData = res.data.array;
				this.total = res.data.total;
				this.$parent.pointLineTotal = this.total;
				if (this.refreshTotal) {
					this.$parent.total = this.total;
				}
				this.$nextTick(function() {
					$(() => {
						let dom = $('.pointLine-box .el-table__row');
						$.each(dom, function(index, item) {
							if (index % 2 == 0) {
								$(this).css({
									backgroundColor: '#f2fafa'
								});
							} else {
								$(this).css({
									backgroundColor: '#f0f2fc'
								});
							}
						});
					});
				});
			});
		},
		// 添加测点(暂时无用)
		addPoints() {
			var text = this.addPoint.name;
			if (text.toString() == '') {
				this.$message({
					showClose: true,
					message: '测点名称不能为空',
					type: 'warning'
				});
				return;
			}
			var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
			if (escape(text).indexOf('%u') < 0) {
				if (flag.test(text)) {
					this.$message({
						showClose: true,
						message: '测点名称不能含有英文符号或特殊符号！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return;
			}
			var data = new FormData();
			data.append('name', 'BX-' + this.addPoint.name);
			if (this.addPoint.position) {
				data.append('address', this.addPoint.position);
			}
			let wvs = [];
			let numExp = new RegExp('^[0-9]*$');
			try {
				this.addPoint.wvs.forEach((item, index) => {
					// console.log(numExp.test(item.value));

					if (item.value != '') {
						if (!numExp.test(item.value)) {
							throw Error(`报警值${index + 1}输入值不是纯数字！请输入纯数字后重试`);
						}
						wvs.push(item.value);
					}
				});
			} catch (e) {
				this.$message({
					showClose: true,
					message: e.message,
					type: 'warning'
				});
				return;
			}
			if (wvs.length != 0) {
				data.append('wvs', wvs.toString());
			}
			var file = this.$refs.addPointImg.getFile();
			if (file.length != 0) {
				data.append('file', file[0].raw);
			}
			this.axios.post('web2/dfmt/mand/dmj/adp', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '测点新增成功',
						type: 'success'
					});
					this.close('edit-point');
				}
			});
		},
		// 编辑测点
		pointEdit() {
			var text = this.addPoint.name;
			if (text.toString() == '') {
				this.$message({
					showClose: true,
					message: '测点名称不能为空',
					type: 'warning'
				});
				return;
			}
			var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
			if (escape(text).indexOf('%u') < 0) {
				if (flag.test(text)) {
					this.$message({
						showClose: true,
						message: '测点名称不能含有英文符号或特殊符号！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return;
			}
			var data = new FormData();
			data.append('id', this.addPoint.id);
			data.append('name', 'BX-' + this.addPoint.name);
			if (this.addPoint.position) {
				data.append('address', this.addPoint.position);
			}
			let wvs = [];
			let numExp = new RegExp('^[0-9]*$');
			try {
				this.addPoint.wvs.forEach((item, index) => {
					// console.log(numExp.test(item.value));

					if (item.value != '') {
						if (!numExp.test(item.value)) {
							throw Error(`报警值${index + 1}输入值不是纯数字！请输入纯数字后重试`);
						}
						wvs.push(item.value);
					}
				});
			} catch (e) {
				this.$message({
					showClose: true,
					message: e.message,
					type: 'warning'
				});
				return;
			}
			if (wvs.length != 0) {
				data.append('wvs', wvs.toString());
			}
			var judge = this.$refs.addPointImg.getJudgeData();
			if (this.addPoint.pn1 == '' || judge) {
				var file = this.$refs.addPointImg.getFile();
				if (file.length != 0) {
					data.append('file', file[0].raw);
				}
			}
			this.axios.post('web2/dfmt/mand/dmp/mdfp', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '测点信息修改成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('edit-point');
				}
			});
		},
		// 删除测测点
		deletePoint(row) {
			// console.log(row);
			this.$confirm('此操作将删除测点：<strong>' + row.name + '</strong> 下所有数据，是否继续！', '严重警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'icon-big',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'error',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/dfmt/mand/dmp/dept', { id: row.id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: `测点：${row.name} 删除成功！`,
								type: 'success'
							});
							this.$parent.getAllList();
						}
					});
				})
				.catch(() => {});
		},
		// 添加测点线(暂时无用)
		addPointLines() {
			var text = this.addPointLine.name;
			if (text.toString() == '') {
				this.$message({
					showClose: true,
					message: '测点线名称不能为空',
					type: 'warning'
				});
				return;
			}
			var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
			if (escape(text).indexOf('%u') < 0) {
				if (flag.test(text)) {
					this.$message({
						showClose: true,
						message: '测点名称不能含有英文符号或特殊符号！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return;
			}
			var data = {};
			for (let k in this.addPointLine) {
				if (this.addPointLine[k] + '' != '') {
					if (k != 'name') {
						data[k] = this.addPointLine[k];
					} else {
						data[k] = 'BXS-' + this.addPointLine[k];
					}
				}
			}
			this.axios.post('web2/dfmt/mand/dmp/mfpl', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '测点线添加成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('edit-point-line');
				}
			});
		},
		// 编辑测点线
		pointLineEdit() {
			var text = this.addPointLine.name;
			if (text.toString() == '') {
				this.$message({
					showClose: true,
					message: '测点线名称不能为空',
					type: 'warning'
				});
				return;
			}
			var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
			if (escape(text).indexOf('%u') < 0) {
				if (flag.test(text)) {
					this.$message({
						showClose: true,
						message: '测点名称不能含有英文符号或特殊符号！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return;
			}
			var data = {
				// id: this.addPointLine.id,
				// name: 'BXS-' + this.addPointLine.name,
				// datum: this.addPointLine.datum
			};
			for (let k in this.addPointLine) {
				if (this.addPointLine[k] + '' != '') {
					if (k != 'name') {
						data[k] = this.addPointLine[k];
					} else {
						data[k] = 'BXS-' + this.addPointLine[k];
					}
				}
			}
			this.axios.post('web2/dfmt/mand/dmp/mfpl', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '测点线信息修改成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('edit-point-line');
				}
			});
		},
		// 删除测点线
		deletePointLine(row) {
			this.$confirm('此操作将删除测点线：<strong>' + row.name + '</strong> 下所有数据，是否继续！', '严重警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'icon-big',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'error',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/dfmt/mand/dmp/dtpl', { id: row.id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: `测点线：${row.name} 删除成功！`,
								type: 'success'
							});
							this.$parent.getAllList();
						}
					});
				})
				.catch(() => {});
		},
		// 预览现场图片
		previewImage(e = null, url) {
			this.sceneImgList = this.constData.imgUrl + 'res/static/gdpn/' + url;
			this.dialogVisible = true;
		},
		// 关闭现场弹窗预览
		handleClose() {
			this.dialogVisible = false;
		},
		// 表格行点击
		rowClick(row, column, event) {
			event.stopPropagation();
			event.preventDefault();
			if (row.hasOwnProperty('pdata')) {
				if (row.pdata.length != 0) {
					$(event.currentTarget)
						.find('.el-icon-arrow-right')
						.click();
				}
			}
		},
		// 设置表格行样式，隐藏下拉
		setRowClass({ row, rowIndex }) {
			let className = 'pointer';
			if (row.hasOwnProperty('pdata')) {
				if (row.pdata.length == 0) {
					className = 'hide-expand-icon';
				}
			} else {
				className = 'hide-expand-icon';
			}
			return className;
		},
		// 添加测点添加、编辑报警值输入框
		addWvsInput(e) {
			let len = this.addPoint.wvs.length;
			this.$set(this.addPoint.wvs, len, {
				name: '报警值',
				value: ''
			});
		},
		// 打开弹窗
		open(val, status = false, data = {}) {
			$('.' + val).show();

			if (val == 'edit-point') {
				// 打开采集器新增设备弹窗
				this.editPoint = status;
				if (status) {
					for (let k in this.addPoint) {
						if (data.hasOwnProperty(k)) {
							if (k != 'name' && k != 'wvs') {
								this.addPoint[k] = data[k];
							} else {
								if (k == 'name') {
									let name = data[k].split('-');
									if (name.length == 2) {
										this.addPoint[k] = name[1];
									}
								} else if (k == 'wvs') {
									let wvs = data[k].split(',');
									wvs.forEach((item, index) => {
										this.$set(this.addPoint.wvs, index, {
											name: '报警值',
											value: item
										});
									});
								}
							}
						}
					}
					let url = this.constData.imgUrl + 'res/static/gdpn/' + data.pn1;
					this.$refs.addPointImg.setFileData(url);
					// // console.log(this.addPoint);
				}
			} else if (val == 'edit-point-line') {
				this.editPointLine = status;
				if (status) {
					for (let k in this.addPointLine) {
						if (data.hasOwnProperty(k)) {
							if (k != 'name' && k != 'datum') {
								this.addPointLine[k] = data[k];
							} else {
								if (k == 'name') {
									let name = data[k].split('-');
									if (name.length == 2) {
										this.addPointLine[k] = name[1];
									}
								} else if (k == 'datum') {
									if (data[k]) {
										this.addPointLine[k] = data[k];
									}
								}
							}
						}
					}
					if (data.hasOwnProperty('pdata')) {
						this.benchmarkOptions = data.pdata;
					}
				}
			}
		},
		// 关闭弹窗
		close(val) {
			$('.' + val).hide();
			if (val == 'edit-point') {
				this.$refs.addPointImg.clearFile();
				//恢复新增采集器设备数据
				for (let key in this.addPoint) {
					if (key != 'wvs') {
						if (key != 'id') {
							this.addPoint[key] = '';
						} else {
							this.addPoint[key] = null;
						}
					} else {
						this.$set(this.addPoint, key, [
							{
								name: '报警值',
								value: ''
							}
						]);
					}
				}
			} else if (val == 'edit-point-line') {
				this.benchmarkOptions = [];
				this.clearData(this.addPointLine);
			}
		},
		// 清空数据赋值信息
		clearData(data) {
			// // console.log(data)
			for (let key in data) {
				if (key != 'id') {
					data[key] = '';
				} else {
					data[key] = null;
				}
			}
		}
	},
	components: {
		uploadImg,
		ElImageViewer
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.pointLine-box {
	width: 100%;
	height: 100%;

	.green {
		color: #00ff7f;
	}
	.grey {
		color: #c0c4cc;
	}

	.table-box {
		width: 100%;
		height: 100%;

		::v-deep {
			.el-table__header-wrapper {
				tr {
					background-color: #ebeef5;
				}

				.el-table__cell {
					background: none;
					font-size: 15px;
					font-weight: 600;
					color: #4c4e51;
					letter-spacing: 1px;
				}
			}

			.el-table__body-wrapper {
				.el-table__row {
					// cursor: pointer;

					.el-table__cell {
						font-size: 14px;
						color: #606266;
						padding: 10px 0;
						border: none;
					}
				}

				.hide-expand-icon {
					.el-table__expand-icon {
						display: none;
					}
				}

				.table-but {
					.el-button {
						padding: 4px 5px;
						font-size: 16px;
					}
				}

				.descriptions-box {
					padding: 0 20px 0 62px;

					.el-descriptions-item {
						padding-right: 12px;

						.el-descriptions-item__container {
							display: flex;
							align-items: center;

							.el-descriptions-item__label {
								white-space: nowrap;

								.icon-name {
									.opacity {
										opacity: 0;
									}

									.name {
										white-space: nowrap;
										margin-left: 8px;
									}
								}
							}

							.imgIcon {
								font-size: 24px;
								color: #409eff;
							}

							.table-but {
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}
			}
		}
	}

	// 新增/编辑弹窗输入框样式
	.Mask-box {
		position: fixed;

		.main-box {
			.pop-ups-content {
				padding: 10px;
				background-color: #ffffff;

				.cell {
					width: 100%;
					display: flex;
					// flex-direction: column;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 12px;

					::v-deep {
						.el-input__inner {
							color: #000000;
							font-size: 16px;
						}
					}

					.title {
						font-size: 15px;
						color: #606266;
						// margin-bottom: 5px;

						i {
							color: #f20000;
							font-size: 12px;
						}
					}

					.cell-content {
						width: calc(100% - 80px);
						font-size: 16px;
					}
				}

				.tips {
					word-break: break-all;
					font-size: 12px;
					margin-bottom: 12px;

					i {
						color: #f20000;
					}
				}

				.button-box {
					text-align: center;
					padding: 12px 12px 0 12px;
				}
			}
		}
	}
	// 现场图片预览
	::v-deep .scenePictures {
		margin-top: 15%;
		height: 75%;

		.el-dialog__body {
			width: calc(100% - 30px);
			height: calc(100% - 74px);
			padding: 10px 15px;

			.img-box {
				width: 100%;
				height: 100%;

				.image-slot {
					height: 90%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					i {
						font-size: 50px;
					}

					span {
						font-size: 20px;
					}
				}
			}
		}
	}

	// 测点添加、编辑弹窗样式
	.edit-point {
		z-index: 1000;
		display: none;

		.main-box {
			width: 390px;
			height: 500px;
			background-color: #ffffff;

			.pop-ups-content {
				width: calc(100% - 34px);
				height: calc(100% - 20px);
				padding: 10px 17px;

				.input-box {
					width: 100%;
					height: calc(100% - 85px);
				}

				.cell {
					width: calc(100% - 15px);
					padding-right: 15px;

					.title {
						width: 75px;
						display: block;
						font-size: 15px;
						color: #606266;
						// margin-bottom: 5px;

						i {
							color: #f20000;
							font-size: 12px;
						}
					}

					.tip-txt {
						font-size: 14px;
					}

					::v-deep {
						.el-input {
							width: 250px;
						}

						.el-textarea__inner {
							font-size: 16px;
							color: #000000;
						}

						.el-textarea {
							width: 250px;
						}

						.el-button {
							padding: 4px 5px;
							font-size: 16px;
						}
					}

					.pointInput {
						::v-deep {
							.el-input {
								width: 225px;
							}
						}
					}
				}

				.img {
					width: calc(100% - 20px);
					padding-right: 20px;
				}

				.but-input-cell {
					justify-content: flex-start;

					::v-deep {
						.el-input {
							width: 200px;
							margin: 0 15px 0 15px;
						}
					}
				}
			}
		}
	}

	// 测点线添加、编辑弹窗样式
	.edit-point-line {
		z-index: 1000;
		display: none;

		.main-box {
			width: 370px;
			height: 325px;
			background-color: #ffffff;

			.pop-ups-content {
				padding: 10px 17px;

				.cell {
					.title {
						width: 90px;
						display: block;
						font-size: 15px;
						color: #606266;
						// margin-bottom: 5px;

						i {
							color: #f20000;
							font-size: 12px;
						}
					}

					.tip-txt {
						font-size: 14px;
					}

					::v-deep {
						.el-input {
							width: 205px;
						}

						.el-textarea__inner {
							font-size: 16px;
							color: #000000;
						}

						.el-textarea {
							width: 205px;
						}

						// .el-textarea__inner {
						// 	width: 250px;
						// }
					}

					.pointInput {
						::v-deep {
							.el-input {
								width: 205px;
							}
						}
					}
				}
			}
		}
	}
}
</style>

<template>
	<!-- 传感器 -->
	<div class="sensor-box">
		<div class="table-box">
			<el-table :data="tableData" style="width: 100%" height="100%">
				<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
				<el-table-column label="传感器编号" prop="sno"></el-table-column>
				<el-table-column label="采集器编号" prop="cno"></el-table-column>
				<el-table-column label="测点名" prop="pname"></el-table-column>
				<el-table-column label="类型" prop="type">
					<template slot-scope="scope">
						<el-tag size="small">{{ typeFormatter(scope.row.type) }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="绑定" align="center">
					<template slot-scope="scope">
						<div class="table-txt-but">
							<el-button v-show="scope.row.cno == ''" type="warning" size="mini" @click.stop="open('binding', true, scope.row)">未绑定</el-button>
							<el-button v-show="scope.row.cno != ''" type="primary" size="mini" @click.stop="unbundlingSensor(scope.row)">已绑定</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-but">
							<!-- <el-button type="primary" plain size="mini" icon="el-icon-edit" @click="open('add-sensor-equipment', true, scope.row)"></el-button> -->
							<el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteSensor(scope.row)"></el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 传感器添加/编辑设备 -->
		<div class="add-sensor-equipment equipment-style Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editSensorEquipment ? '编辑传感器设备' : '添加传感器设备' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-sensor-equipment')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							传感器编号
						</span>
						<el-input v-show="!editSensorEquipment" v-model="addEquipment.sno" placeholder="请输入内容"></el-input>
						<p class="cell-content" v-show="editSensorEquipment">{{ addEquipment.sno }}</p>
					</div>
					<!--  -->
					<div class="cell" v-show="judgment('authorityList', 290, this)">
						<span class="title">所属企业</span>
						<el-select v-model="addEquipment.companyId" filterable placeholder="请选择企业">
							<el-option v-for="item in allEnterprise" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<span class="title">
							<i>*</i>
							型号
						</span>
						<el-select v-model="addEquipment.type" filterable placeholder="请选择型号">
							<el-option v-for="item in typeOption" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="button-box">
						<el-button type="primary" v-show="!editSensorEquipment" size="mini" @click="addEquipments">添加</el-button>
						<el-button type="primary" v-show="editSensorEquipment" size="mini" @click="equipmentEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 绑定传感器 -->
		<el-dialog :title="'绑定传感器：' + sensorName" :visible.sync="dialogSensor" custom-class="bindingSensor" @close="close('binding')" width="400px" top="16%">
			<div class="mian-box">
				<el-form ref="form" :model="bindingInfo" label-width="80px">
					<el-form-item label="采集器">
						<el-select v-model="bindingInfo.dcid" placeholder="请选择采集器" @change="collectorChange">
							<el-option v-for="(i, index) in collectorOptions" :key="index" :label="i.cno" :value="i.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="项目">
						<span v-show="bindingInfo.dcid + '' != ''">{{ bindingInfo.pjname }}</span>
					</el-form-item>
					<el-form-item label="测点线">
						<span v-show="bindingInfo.dcid + '' != ''">{{ bindingInfo.plname }}</span>
					</el-form-item>
					<el-form-item label="测点">
						<el-select v-show="bindingInfo.dcid + '' != ''" v-model="bindingInfo.pid" :disabled="bindingInfo.plid == 0" placeholder="请选择测点">
							<el-option label="新增测点" value=""></el-option>
							<el-option v-for="(i, index) in pointOptions" :key="index" :label="i.name" :value="i.id"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogSensor = false">取 消</el-button>
				<el-button type="primary" @click="bindingSensor">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		// 权限列表
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		//选择的企业列表
		allEnterprise: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			tableData: [],
			editSensorEquipment: false, //是否编辑传感器设备
			dialogSensor: false, // 绑定传感器弹窗是否打开
			sensorName: '', // 要绑定的传感器编号
			bindingInfo: {
				id: '', //传感器id
				dcid: '', // 采集器id
				pid: '', // 测点id
				plid: '', //测点线id
				pjname: '', // 项目名称
				plname: '' // 测点线名称
			}, //绑定传感器信息
			collectorOptions: [], //绑定传感器弹窗采集器选择
			pointOptions: [], //绑定传感器弹窗测点选择
			typeOption: [
				{
					name: '静力水准仪',
					id: 1
				},
				{
					name: '倾角计',
					id: 2
				},
				{
					name: '裂缝计',
					id: 3
				},
				{
					name: '激光测距仪',
					id: 4
				}
			], //新增项目选择的型号列表
			addEquipment: {
				sno: '', // 编号
				type: '', // 设备型号
				id: '',
				companyId: '' //企业id
			}, // 添加设备/设备编辑
			requestInfo: {
				page: 1,
				size: 30,
				name: ''
			},
			refreshTotal: false, //是否刷新父组件的分页总数
			total: 0 //数据总数
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 获取列表数据
		getListData(value = {}, total = false) {
			for (let k in this.requestInfo) {
				if (value.hasOwnProperty(k)) {
					this.requestInfo[k] = value[k];
				}
			}
			this.refreshTotal = total;
			var data = {
				name: this.requestInfo.name,
				page: this.requestInfo.page,
				size: this.requestInfo.size,
				projectId: this.$parent.project_info.id,
				companyId: this.$parent.enterprise_info.id
			};
			this.axios.post('web2/dfmt/mand/odnr/gslist', data).then(res => {
				this.tableData = [];
				if (res.status) {
					this.tableData = res.data.array;
					this.total = res.data.total;
					this.$parent.sensorTotal = this.total;
					if (this.refreshTotal) {
						this.$parent.total = this.total;
					}
				}
			});
		},
		// 添加传感器设备
		addEquipments() {
			var data = {
				sno: this.addEquipment.sno,
				type: this.addEquipment.type,
				projectId: this.$parent.project_info.id
			};
			if (data.sno.toString() == '' || data.type.toString() == '') {
				this.$message({
					showClose: true,
					message: '请填写必填项',
					type: 'warning'
				});
				return;
			}
			if (this.addEquipment.companyId == '' || this.addEquipment.companyId == null) {
				data.companyId = this.$parent.enterprise_info.id;
			} else {
				data.companyId = this.addEquipment.companyId;
			}
			this.axios.post('web2/dfmt/mand/dmj/ads', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备新增成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('add-sensor-equipment');
				}
			});
		},
		// 编辑传感器设备
		equipmentEdit() {
			var data = {
				id: this.addEquipment.id
				// type: this.addEquipment.type
			};
			if (this.addEquipment.companyId != '' && this.addEquipment.companyId != null) {
				data.companyId = this.addEquipment.companyId;
			}
			this.axios.post('web2/dfmt/mand/dmm/mds', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备信息修改成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('add-sensor-equipment');
				}
			});
		},
		// 删除传感器
		deleteSensor(row) {
			this.$confirm('此操作将删除传感器：<strong>' + row.sno + '</strong>，是否继续！', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/dfmt/mand/dmj/dts', { id: row.id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: `传感器：${row.sno} 删除成功！`,
								type: 'success'
							});
							this.$parent.getAllList();
						}
					});
				})
				.catch(() => {});
		},
		// 获取绑定弹窗数据（查询采集器信息） web2/dfmt/mand/dmm/gcpi
		getSensorInfo() {
			let data = {
				projectId: this.$parent.project_info.id,
				companyId: this.$parent.enterprise_info.id
			};
			this.collectorOptions = [];
			this.axios.post('web2/dfmt/mand/dmm/gcpi', data).then(res => {
				this.collectorOptions = [];
				if (res.status) {
					this.collectorOptions = res.data;
				}
			});
		},
		// 绑定传感器采集器选择
		collectorChange(val) {
			try {
				this.collectorOptions.forEach(item => {
					if (item.id == this.bindingInfo.dcid) {
						for (let k in this.bindingInfo) {
							if (item.hasOwnProperty(k)) {
								if (k != 'id') {
									this.bindingInfo[k] = item[k];
								}
								this.pointOptions = item.points;
							}
						}
						throw Error();
					}
				});
			} catch (e) {
				return;
				//TODO handle the exception
			}
		},
		// 绑定 web2/dfmt/mand/dmm/bspt
		bindingSensor() {
			let data = {
				id: '', //传感器id
				dcid: '', // 采集器id
				pid: '' // 测点id
			};
			for (let k in data) {
				if (this.bindingInfo.hasOwnProperty(k)) {
					data[k] = this.bindingInfo[k];
				}
			}
			this.axios.post('web2/dfmt/mand/dmm/bspt', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: `传感器：${this.sensorName} 绑定成功！`,
						type: 'success'
					});
					this.dialogSensor = false;
					this.$parent.getAllList();
				}
			});
		},
		// 解绑 web2/dfmt/mand/dmm/resp
		unbundlingSensor(row) {
			this.$confirm('确定解绑传感器：<strong>' + row.sno + '</strong>', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/dfmt/mand/dmm/resp', { id: row.id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: `传感器：${row.sno} 解绑成功！`,
								type: 'success'
							});
							this.$parent.getAllList();
						}
					});
				})
				.catch(() => {});
		},
		// 传感器类型数据回显格式化
		typeFormatter(val) {
			let txt = '';
			try {
				this.typeOption.forEach(item => {
					if (item.id == val) {
						txt = item.name;
						throw Error();
					}
				});
			} catch (e) {
				//TODO handle the exception
				return txt;
			}
			return txt;
		},
		// 打开弹窗
		open(val, status, data = {}) {
			if (val != 'binding') {
				$('.' + val).show();
			}

			if (val == 'add-sensor-equipment') {
				// 打开采集器新增设备弹窗
				this.editSensorEquipment = status;
				if (status) {
					for (let k in this.addEquipment) {
						if (data.hasOwnProperty(k)) {
							this.addEquipment[k] = data[k];
						}
					}
				}
			} else if (val == 'binding') {
				this.bindingInfo.id = data.id;
				this.sensorName = data.sno;
				this.getSensorInfo();
				this.dialogSensor = true;
			}
		},
		// 关闭弹窗
		close(val) {
			if (val != 'binding') {
				$('.' + val).hide();
			}
			if (val == 'add-sensor-equipment') {
				//恢复新增采集器设备数据
				for (let key in this.addEquipment) {
					this.addEquipment[key] = '';
				}
			} else if (val == 'binding') {
				this.clearData(this.bindingInfo);
			}
		},
		// 清空数据赋值信息
		clearData(data) {
			// // console.log(data)
			for (let key in data) {
				if (key != 'id') {
					data[key] = '';
				} else {
					data[key] = null;
				}
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.sensor-box {
	width: 100%;
	height: 100%;

	.green {
		color: #00ff7f;
	}
	.grey {
		color: #c0c4cc;
	}

	.table-box {
		width: 100%;
		height: 100%;

		::v-deep {
			.el-table__header-wrapper {
				tr {
					background-color: #ebeef5;
				}

				.el-table__cell {
					background: none;
					font-size: 15px;
					font-weight: 600;
					color: #4c4e51;
					letter-spacing: 1px;
				}
			}

			.el-table__body-wrapper {
				.el-table__row:nth-child(even) {
					background-color: #f0f2fc;
				}

				.el-table__row:nth-child(odd) {
					background-color: #f2fafa;
				}

				.el-table__cell {
					font-size: 14px;
					color: #606266;
					padding: 10px 0;
					border: none;
				}

				.table-txt-but {
					.el-button {
						padding: 6px 6px;
					}

					.el-button + .el-button {
						margin-left: 0;
					}
				}

				.table-but {
					.el-button {
						padding: 4px 5px;
						font-size: 16px;
					}
				}
			}
		}
	}

	// 新增/编辑弹窗输入框样式
	.Mask-box {
		position: fixed;

		.main-box {
			.pop-ups-content {
				padding: 10px;
				background-color: #ffffff;

				.cell {
					width: 100%;
					display: flex;
					// flex-direction: column;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 12px;

					::v-deep {
						.el-input__inner {
							color: #000000;
							font-size: 16px;
						}
					}

					.title {
						font-size: 15px;
						color: #606266;
						// margin-bottom: 5px;

						i {
							color: #f20000;
							font-size: 12px;
						}
					}

					.cell-content {
						width: calc(100% - 120px);
						font-size: 16px;
					}
				}

				.tips {
					word-break: break-all;
					font-size: 12px;
					margin-bottom: 12px;

					i {
						color: #f20000;
					}
				}

				.button-box {
					text-align: center;
					padding: 12px 12px 0 12px;
				}
			}
		}
	}

	// 设备添加、编辑弹窗样式
	.equipment-style {
		z-index: 1000;
		display: none;

		.main-box {
			width: 380px;
			height: 311px;

			.pop-ups-content {
				.cell {
					::v-deep {
						.el-input {
							width: calc(100% - 120px);
						}

						.el-select {
							width: calc(100% - 120px);

							.el-input {
								width: 100%;
							}
						}

						.el-switch__label * {
							font-size: 13px;
							color: #dcdfe6;
						}

						.is-active span {
							color: #409eff !important;
						}
					}

					.title {
						width: 110px;
						display: inline-block;
					}
				}
			}
		}
	}

	// 绑定传感器弹窗
	::v-deep .bindingSensor {
		.el-dialog__body {
			padding: 15px 15px 20px 15px;
		}

		.mian-box {
			width: 100%;

			.el-form {
				.el-form-item {
					margin-bottom: 10px;
				}
			}
		}
	}
}
</style>

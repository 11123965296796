<template>
	<div class="dataMovement-box">
		<div class="movement-main">
			<div class="left-box content-cell">
				<div class="title-box">{{ leftTitle }}</div>
				<div class="select-box">
					<el-checkbox-group v-model="leftCheck" @change="leftChange">
						<div class="cell-box">
							<vue-scroll>
								<div class="cell pointer" v-for="(i, index) in leftData" :key="index" @click="leftChange(i.id, $event)">
									<el-descriptions title="" :colon="false">
										<el-descriptions-item label="" content-class-name="check"><el-checkbox :label="i[leftKey.id]"></el-checkbox></el-descriptions-item>
										<el-descriptions-item label="">{{ index + 1 }}</el-descriptions-item>
										<el-descriptions-item label="" content-class-name="text-overflow">
											<el-tooltip class="item" effect="dark" :content="i[leftKey.name]" placement="top">
												<p class="txt text-overflow">{{ i[leftKey.name] }}</p>
											</el-tooltip>
										</el-descriptions-item>
									</el-descriptions>
								</div>
							</vue-scroll>
						</div>
					</el-checkbox-group>
				</div>
			</div>
			<div class="box-but-box">
				<el-input type="number" placeholder="请输入" v-model="dataIndex" class="input-with-select">
					<span slot="prepend">移动到序号</span>
					<el-button slot="append" @click="moveTo">确定</el-button>
				</el-input>
				<el-button type="primary" size="mini" class="margin" :disabled="upDisabled" @click="movingUpward">向上移</el-button>
				<el-button type="primary" size="mini" :disabled="downDisabled" @click="moveDown">向下移</el-button>
			</div>
			<div class="right-box content-cell">
				<div class="title-box">{{ rightTitle }}</div>
				<div class="select-box">
					<el-checkbox-group v-model="rightCheck" @change="rightChange">
						<div class="cell-box">
							<vue-scroll>
								<div class="cell pointer" v-for="(i, index) in rightData" :key="index" @click="rightChange(i.id, $event)">
									<el-descriptions title="" :colon="false">
										<el-descriptions-item label="" content-class-name="check"><el-checkbox :label="i[rightKey.id]"></el-checkbox></el-descriptions-item>
										<el-descriptions-item label="">{{ index + 1 }}</el-descriptions-item>
										<el-descriptions-item label="" content-class-name="text-overflow">
											<el-tooltip class="item" effect="dark" :content="i[rightKey.name]" placement="top">
												<p class="txt text-overflow">{{ i[rightKey.name] }}</p>
											</el-tooltip>
										</el-descriptions-item>
									</el-descriptions>
								</div>
							</vue-scroll>
						</div>
					</el-checkbox-group>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		listData: {
			type: Object,
			default: () => {
				return {
					left: [],
					right: []
				};
			}
		},
		// 左边数据key值
		leftKey: {
			type: Object,
			default: () => {
				return {
					id: 'id',
					name: 'name'
				};
			}
		},
		// 右边数据key值
		rightKey: {
			type: Object,
			default: () => {
				return {
					id: 'id',
					name: 'name'
				};
			}
		},
		leftTitle: {
			type: String,
			default: ''
		},
		rightTitle: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			leftData: [], //左边数据
			rightData: [], // 右边数据
			leftCheck: [], //左边选中数据
			rightCheck: [], // 右边选中数据
			dataIndex: '', // 输入的数据需要移动到的index
			upDisabled: false, // 上移按钮是否禁用
			downDisabled: false // 下移按钮是否禁用
		};
	},
	created() {},
	mounted() {
		if (this.listData.hasOwnProperty('left')) {
			this.leftData = this.deepCopy(this.listData.left);
		}
		if (this.listData.hasOwnProperty('right')) {
			this.rightData = this.deepCopy(this.listData.right);
		}
	},
	computed: {},
	methods: {
		// 获取左右两边的数据
		getAfterMoving() {
			return {
				left: this.leftData,
				right: this.rightData
			};
		},
		// 左边多选框选择
		leftChange(val, e = null) {
			// // console.log(val);
			if (e != null) {
				e.stopPropagation();
				e.preventDefault();
			}
			if (typeof val != 'object') {
				if (this.leftCheck.includes(Number(val))) {
					this.leftCheck = [];
				} else {
					this.leftCheck = [Number(val)];
				}
			} else {
				if (val.length >= 2) {
					this.leftCheck = [val[1]];
				}
			}
			this.rightCheck = [];
		},
		// 右边多选框选择
		rightChange(val, e = null) {
			if (e != null) {
				e.stopPropagation();
				e.preventDefault();
			}
			if (typeof val != 'object') {
				if (this.rightCheck.includes(Number(val))) {
					this.rightCheck = [];
				} else {
					this.rightCheck = [Number(val)];
				}
			} else {
				if (val.length >= 2) {
					this.rightCheck = [val[1]];
				}
			}
			this.leftCheck = [];
		},
		// 向上移
		movingUpward() {
			if (this.leftCheck.length != 0) {
				let index = this.leftData.findIndex(item => {
					return item.id == this.leftCheck[0];
				});
				if (index != 0 && index != -1) {
					let previous = this.deepCopy(this.leftData[index - 1]); //选中数据的上一个数据
					let current = this.deepCopy(this.leftData[index]); //选中数据
					this.$set(this.leftData, index - 1, current);
					this.$set(this.leftData, index, previous);
				} else if (index == 0) {
					this.$message({
						message: '当前数据已在第一位！',
						type: 'warning'
					});
				}
			} else if (this.rightCheck.length != 0) {
				let index = this.rightData.findIndex(item => {
					return item.id == this.rightCheck[0];
				});
				if (index != 0 && index != -1) {
					let previous = this.deepCopy(this.rightData[index - 1]); //选中数据的上一个数据
					let current = this.deepCopy(this.rightData[index]); //选中数据
					this.$set(this.rightData, index - 1, current);
					this.$set(this.rightData, index, previous);
				} else if (index == 0) {
					this.$message({
						message: '当前数据已在第一位！',
						type: 'warning'
					});
				}
			}
		},
		// 向下移
		moveDown() {
			if (this.leftCheck.length != 0) {
				let index = this.leftData.findIndex(item => {
					return item.id == this.leftCheck[0];
				});
				let len = this.leftData.length;
				if (index != len - 1 && index != -1) {
					let next = this.deepCopy(this.leftData[index + 1]); //选中数据的下一个数据
					let current = this.deepCopy(this.leftData[index]); //选中数据
					this.$set(this.leftData, index + 1, current);
					this.$set(this.leftData, index, next);
				} else if (index == len - 1) {
					this.$message({
						message: '当前数据已在最后一位！',
						type: 'warning'
					});
				}
			} else if (this.rightCheck.length != 0) {
				let index = this.rightData.findIndex(item => {
					return item.id == this.rightCheck[0];
				});
				let len = this.rightData.length;
				if (index != len - 1 && index != -1) {
					let next = this.deepCopy(this.rightData[index + 1]); //选中数据的上一个数据
					let current = this.deepCopy(this.rightData[index]); //选中数据
					this.$set(this.rightData, index + 1, current);
					this.$set(this.rightData, index, next);
				} else if (index == len - 1) {
					this.$message({
						message: '当前数据已在最后一位！',
						type: 'warning'
					});
				}
			}
		},
		// 移动到指定位置
		moveTo() {
			if (this.leftCheck.length != 0) {
				let index = this.leftData.findIndex(item => {
					return item.id == this.leftCheck[0];
				});
				let moveIndex = this.dataIndex - 1;
				let len = this.leftData.length;
				if (moveIndex < 0 || moveIndex > len - 1) {
					this.$message({
						message: `请输入0——${len - 1}的合规数字！`,
						type: 'warning'
					});
					return;
				}
				if (index != len - 1 && index != -1) {
					let moveData = this.deepCopy(this.leftData[moveIndex]); //选中数据需要移动到的位置的数据
					let current = this.deepCopy(this.leftData[index]); //选中数据
					this.$set(this.leftData, moveIndex, current);
					this.$set(this.leftData, index, moveData);
				} else if (index == moveIndex) {
					this.$message({
						message: '当前数据已移动到指定位置！',
						type: 'warning'
					});
				}
			} else if (this.rightCheck.length != 0) {
				let index = this.rightData.findIndex(item => {
					return item.id == this.rightCheck[0];
				});
				let moveIndex = this.dataIndex - 1;
				let len = this.rightData.length;
				if (moveIndex < 0 || moveIndex > len - 1) {
					this.$message({
						message: `请输入0——${len - 1}的合规数字！`,
						type: 'warning'
					});
					return;
				}
				if (index != len - 1 && index != -1) {
					let moveData = this.deepCopy(this.rightData[moveIndex]); //选中数据需要移动到的位置的数据
					let current = this.deepCopy(this.rightData[index]); //选中数据
					this.$set(this.rightData, moveIndex, current);
					this.$set(this.rightData, index, moveData);
				} else if (index == moveIndex) {
					this.$message({
						message: '当前数据已移动到指定位置！',
						type: 'warning'
					});
				}
			}
		},
		// 组件关闭清除数据
		clearData(val = null, data = '') {
			if (val == null) {
				this.leftData = [];
				this.rightData = [];
				this.leftCheck = [];
				this.rightCheck = [];
				this.dataIndex = '';
				this.upDisabled = false;
				this.downDisabled = false;
			} else {
				this[val] = data;
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {
		listData: {
			handler: function(nv) {
				// console.log(nv);
				if (nv.hasOwnProperty('left')) {
					this.leftData = this.deepCopy(nv.left);
				}
				if (nv.hasOwnProperty('right')) {
					this.rightData = this.deepCopy(nv.right);
				}
			},
			deep: true
		}
	}
};
</script>

<style scoped lang="scss">
.dataMovement-box {
	width: 100%;
	height: 100%;

	.movement-main {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.content-cell {
			width: calc(50% - 122px);
			height: 100%;
			border: 1px solid #dcdfe6;
			border-radius: 4px;

			.title-box {
				width: 100%;
				height: 30px;
				line-height: 30px;
				text-align: center;
				color: #000;
				font-size: 15px;
				background-color: #e4e7ed;
			}

			.select-box {
				width: calc(100% - 20px);
				height: calc(100% - 40px);
				padding: 10px 10px 0 10px;

				::v-deep {
					.el-checkbox__label {
						display: none;
					}

					.el-descriptions {
						.el-descriptions-item {
							padding-bottom: 0;

							.el-descriptions-item__label {
								margin-right: 0;
							}

							.el-checkbox__input {
								line-height: 1.5;
							}

							.txt {
								width: 100%;
							}

							.el-descriptions-item__content {
								width: 100%;
							}
						}

						.el-descriptions-item:first-child {
							width: 20px;
						}

						.el-descriptions-item:nth-child(2) {
							width: 30px;
							text-align: center;
						}
					}
				}
			}
		}

		.box-but-box {
			display: flex;
			flex-direction: column;
			align-items: center;

			::v-deep {
				.el-button + .el-button {
					margin-left: 0;
				}

				.el-input-group {
					.el-input-group__prepend {
						padding: 0 5px;
					}

					.el-input__inner {
						width: 63px;
					}

					.el-input-group__append {
						padding: 0 10px;
						color: #fff;
						background-color: #409eff;
						border-color: #409eff;
					}
				}
			}

			.margin {
				margin: 15px 0;
			}
		}
	}
}
</style>
